import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import Router from "@/router";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        // Vue.axios.defaults.baseURL = "http://127.0.0.1:9080";
        Vue.axios.defaults.withCredentials = true;

        //请求拦截器
        Vue.axios.interceptors.request.use((config) => {
            // 请求成功后返回的内容
            let token = JwtService.getToken() //拦截器获取到token 存储到变量token中
            if (token) {
                //config是一个请求参数,他是一个对象,Authorization 是前后台约定好的那key,把获取到的token赋值给他
                config.headers['auth-token'] = token;
            }
            return config;
        }, (error) => { //请求失败后返回的内容
            return Promise.reject(error);
        });

        //响应拦截器 use(两个参数)
        Vue.axios.interceptors.response.use((response) => {
            // 请求成功对响应数据做处理
            // 该返回的数据则是axios.then(res)中接收的数据
            return response;
        }, (error) => {
            if (error.response && error.response.status === 401) {
                Router.push("/login");
            }
            // 在请求错误时要做的事儿
            // 该返回的数据则是axios.catch(err)中接收的数据
            return Promise.reject(error)
        });
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common["Cookie"] = `${JwtService.getCookie()}`;
    },

    query(resource, params) {
        return Vue.axios.get(resource, params).catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @returns {*}
     */
    get(resource) {
        return Vue.axios.get(`${resource}`).catch(error => {
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        return Vue.axios.post(`${resource}`, params);
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;
