import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const INIT_ACCOUNT_INFO = "initUserAccountInfo";

// mutation types
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
  user_account_info: {
    userName: "",
    realName: "",
    userType: "",
    phone: "",
    email: "",
    qq: "",
    wechat: "",
    registerDate: "",
    verification: true
  }
};

const getters = {
  currentUserAccountInfo(state) {
    return state.user_account_info;
  }
};

const actions = {
  [INIT_ACCOUNT_INFO](context) {
    ApiService.get("/api/profile/user").then(({data}) => {
      if(data.success){
        context.commit(SET_ACCOUNT_INFO, data.result);
      }
    })
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    return new Promise(resolve => {
      ApiService.post("/api/edit/user",payload).then(({data}) => {
        if (data.success) {
           context.commit(SET_ACCOUNT_INFO, payload);
        } else {
          console.info("error:", data);
        }
        resolve(data);
      }).catch(({response}) => {
          console.info("error:", JSON.stringify(response));
      });
    });
  }
};

const mutations = {
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
