import ApiService from "@/core/services/api.service";
import Vue from "vue";
const state = {
    notice: {},
    contact: {
        qq: [],
        wechat: []
    }
};

const getters = {
    getNotice(state) {
        return state.notice;
    },
    getContact(state) {
        return state.contact;
    },
};

const actions = {
    init_notice(context) {
        ApiService.get("/api/setting/query").then(({data}) => {
            if (data.success) {
                let notice = {
                    isShow: data.result.showContent == 1,
                    content: data.result.noticeContent
                }
                context.commit("setNotice", notice);
                let contact = {
                    qq: [],
                    wechat: []
                }
                let qqItems = data.result.contactQQ;
                if (qqItems != null && qqItems != undefined && qqItems != "") {
                    contact.qq = JSON.parse(qqItems);
                }

                let images = data.result.contactWechat;
                if (images != null && images != undefined && images != "") {
                    let items = JSON.parse(images);
                    items.forEach(function (image) {
                        contact.wechat.push({qrcode: image.imageUrl});
                    });
                    context.commit("setContact", contact);
                }
            }
        }).catch(({response}) => {
            console.error("init error:", JSON.stringify(response))
        });
    }
};

const mutations = {
    setNotice(state, notice) {
       state.notice=notice;
    },
    setContact(state, contact) {
        state.contact=contact;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
