import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
// import Moment from 'moment';
import moment from 'moment-timezone';

moment.tz.setDefault("Asia/Kolkata");

// 定义全局时间戳过滤器
Vue.filter('formatDate', function (value) {
    if (value == undefined || value == null || value == "") {
        return "";
    }
    return moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.directive('focus', {
    // 当被绑定的元素插入到 DOM 中时……
    // 指使用 v-focus 的元素
    inserted: function (el) {
        // 聚焦元素
        el.focus()
    }
})

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue"; Vue.directive('focus', {
    // 当被绑定的元素插入到 DOM 中时……
    // 指使用 v-focus 的元素
    inserted: function (el) {
        // 聚焦元素
        el.focus()
    }
})
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    //Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
    next();

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
