import ApiService from "@/core/services/api.service";
import Vue from "vue";

const MY_CARTS = "user_shopping_carts";
const PRE_SKUS = "pre_skus";


const state = {
    mycarts: [],
    preskus: []
};

const getters = {
    shoppingCarts(state) {
        return state.mycarts;
    },
    preSkus(state) {
        return state.preskus;
    },
};

const actions = {
    init_cart(context) {
        console.debug("init_cart");
        context.commit("initCarts");
    },
    init_preskus(context) {
        console.debug("init_preskus");
        context.commit("initPreSkus");
    },
    add_cart(context, cart) {
        console.debug("add_cart:" + JSON.stringify(cart));
        context.commit("setCarts", cart);
    },
    update_cart(context, cart) {
        console.debug("update_cart:" + JSON.stringify(cart));
        context.commit("updateCart", cart);
    },
    delete_cart(context, skuId) {
        console.debug("delete_cart:" + skuId);
        context.commit("deleteCart", skuId);
    },
    empty_cart(context) {
        console.debug("empty_cart");
        context.commit("emptyCart");
    },
    set_preskus(context, preskus) {
        console.debug("set_preskus:" + JSON.stringify(preskus));
        context.commit("setPreSkus", preskus);
    }
};

const mutations = {
    initCarts(state) {
        var mycarts = window.localStorage.getItem(MY_CARTS);
        console.info("init_cart:" + mycarts);
        if (mycarts != undefined && mycarts != null && mycarts != '') {
            mycarts = JSON.parse(mycarts);
            state.mycarts = mycarts;
        }
    },
    initPreSkus(state) {
        var preskus = window.localStorage.getItem(PRE_SKUS);
        console.info("init_skus:" + preskus);
        if (preskus != undefined && preskus != null && preskus != '') {
            preskus = JSON.parse(preskus);
            state.preskus = preskus;
        }
    },
    setCarts(state, cart) {
        var index = state.mycarts.findIndex(item => item.skuId == cart.skuId);
        if (index > -1) {
            var storaged = state.mycarts[index];
            storaged.quantity = storaged.quantity + cart.quantity;
            state.mycarts[index] = storaged;
        } else {
            state.mycarts.push(cart);
        }
        window.localStorage.setItem(MY_CARTS, JSON.stringify(state.mycarts));
    },
    updateCart(state, cart) {
        var index = state.mycarts.findIndex(item => item.skuId == cart.skuId);
        if (index > -1) {
            var storaged = state.mycarts[index];
            storaged.quantity = storaged.quantity + cart.quantity;
            state.mycarts[index] = storaged;
        }
        window.localStorage.setItem(MY_CARTS, JSON.stringify(state.mycarts));
    },
    deleteCart(state, skuId) {
        var index = state.mycarts.findIndex(item => item.skuId == skuId);
        if (index > -1) {
            state.mycarts.splice(index, 1);
        }
        window.localStorage.setItem(MY_CARTS, JSON.stringify(state.mycarts));
    },
    emptyCart(state) {
        console.debug("empty carts:");
        state.mycarts = [];
        state.preskus = [];
        window.localStorage.removeItem(MY_CARTS);
        window.localStorage.removeItem(PRE_SKUS);
    },
    setPreSkus(state, preskus) {
        state.preskus = preskus;
        window.localStorage.setItem(PRE_SKUS, JSON.stringify(state.preskus));
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
