const USER_CREDENTIALS = "user_credentials";
const XXX_TOKEN = "auth-token";

export const getCredentials = () => {
    // let credentials = VueCookies.get(USER_CREDENTIALS);
    let credentials = window.sessionStorage.getItem(USER_CREDENTIALS);
    if (credentials == null || credentials == undefined || credentials == "undefined") {
        return {};
    }
    return JSON.parse(credentials);
};


export const saveCredentials = credentials => {
    // VueCookies.set(USER_CREDENTIALS, JSON.stringify(credentials));
    window.sessionStorage.setItem(USER_CREDENTIALS, JSON.stringify(credentials));
};

export const destroyCredentials = () => {
    console.debug("destroyCredentials PURGE_AUTH");
    // VueCookies.remove(USER_CREDENTIALS);
    window.sessionStorage.removeItem(USER_CREDENTIALS);
};


export const getToken = () => {
    // return VueCookies.get(XXX_TOKEN);
    return window.sessionStorage.getItem(XXX_TOKEN);
};

export const saveToken = token => {
    // VueCookies.set(XXX_TOKEN, token);
    window.sessionStorage.setItem(XXX_TOKEN, token);
};

export const destroyToken = () => {
    // VueCookies.remove(XXX_TOKEN);
    window.sessionStorage.removeItem(XXX_TOKEN);
};

export default {saveCredentials, getCredentials, destroyCredentials, getToken, saveToken, destroyToken};
