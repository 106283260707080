import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";
import Qs from 'qs';

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

export const TOKEN_NAME = "auth-token";


const state = {
    errors: null,
    credentials: JwtService.getCredentials(),
    token: "",
    isAuthenticated: JwtService.getToken() != null && JwtService.getToken() != undefined
};

const getters = {
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    currentUser(state) {
        return state.credentials.currentUser;
    },
    extrasInfo(state) {
        return state.credentials.extrasInfo;
    },
    userMenus(state) {
        return state.credentials.menus;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise(resolve => {
            Vue.axios.post("/api/login.do",
                Qs.stringify(credentials), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                .then(({data, status, statusText, headers}) => {
                    if (data.success) {
                        context.commit(SET_TOKEN, headers);
                        context.commit(SET_AUTH, data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }
                    resolve(data);
                }).catch(({response}) => {
                if (response != undefined) {
                    context.commit(SET_ERROR, "登录异常");
                }
            });
        });
    },
    [LOGOUT](context) {
        ApiService.post("/api/logout.do").then(({data}) => {
            context.commit(PURGE_AUTH);
        }).catch(({response}) => {
            console.info("logout error:", JSON.stringify(response));
            context.commit(SET_ERROR, "退出失败");
        });
    },
    [REGISTER](context, credentials) {
        return new Promise(resolve => {
            ApiService.post("/api/user/register", credentials).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                console.info("register error:"+JSON.stringify(response));
                context.commit(SET_ERROR, "注册异常!");
            });
        });
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getCredentials() != null) {
            ApiService.get("/api/login/verify").then(({data}) => {
                if (data.code === "200") {

                } else if (data.code == "401") {
                    context.commit(PURGE_AUTH);
                }
            }).catch(({response}) => {
                context.commit(SET_ERROR, response.data.errors);
                context.commit(PURGE_AUTH);
            });
        } else {
            context.commit(PURGE_AUTH);
        }
    },

    [UPDATE_PASSWORD](context, payload) {
        const password = payload;
        return ApiService.put("password", password).then(({data}) => {
            context.commit(SET_PASSWORD, data);
            return data;
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_AUTH](state, data) {
        state.isAuthenticated = true;
        let credentials = {
            currentUser: {
                userName: '',
                realName: '',
                userType: null,
            },
            extrasInfo: {},
            menus: {}
        }
        credentials.currentUser.userName = data.result.userName;
        credentials.currentUser.realName = data.result.realName;
        credentials.currentUser.userType = data.result.userType;
        credentials.extrasInfo = data.result.extrasInfo;
        credentials.menus = data.result.authMenus;
        state.credentials = credentials;
        state.errors = {};
        // console.info("before save...")
        JwtService.saveCredentials(credentials);
    },
    [SET_TOKEN](state, headers) {
        state.token = headers[TOKEN_NAME];
        JwtService.saveToken(state.token);
    },
    [SET_PASSWORD](state, password) {
        state.user.password = password;
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.credentials = null;
        state.errors = {};
        state.token = "";
        JwtService.destroyCredentials();
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
