import ApiService from "@/core/services/api.service";
import Vue from "vue";

const PASTER_SETTING = "paster_setting";
const PICKLIST_SETTING = "picklist_setting";


const state = {
    picklist: {

    },
    paster: {

    }
};

const getters = {
    pasterSetting(state) {
        return state.paster;
    },
    picklistSetting(state) {
        return state.picklist;
    },
};

const actions = {
    init(context) {
        context.commit("initPaster");
        context.commit("initPickList");
    },
    set_paster(context, setting) {
        context.commit("setPaster", setting);
    },
    set_pickList(context, setting) {
        context.commit("setPickList", setting);
    }
};

const mutations = {
    initPaster(state) {
        var pasterSetting = window.localStorage.getItem(PASTER_SETTING);
        if (pasterSetting != undefined && pasterSetting != null && pasterSetting != '') {
            pasterSetting = JSON.parse(pasterSetting);
            state.paster = pasterSetting;
        }
    },
    initPickList(state) {
        var picklistSetting = window.localStorage.getItem(PICKLIST_SETTING);
        if (picklistSetting != undefined && picklistSetting != null && picklistSetting != '') {
            picklistSetting = JSON.parse(picklistSetting);
            state.picklist = picklistSetting;
        }
    },
    setPaster(state, setting) {
        state.paster = setting;
        window.localStorage.setItem(PASTER_SETTING, JSON.stringify(state.paster));
    },
    setPickList(state, setting) {
        state.picklist = setting;
        window.localStorage.setItem(PICKLIST_SETTING, JSON.stringify(state.picklist));
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
