import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [{
        path: "/",
        redirect: "/dashboard",
        component: () => import("@/view/layout/Layout"),
        children: [{
            path: "/dashboard",
            name: "dashboard",
            component: () => import("@/view/pages/common/Dashboard.vue")
        },
        {
            path: "/profile",
            name: "profile",
            component: () => import("@/view/pages/common/profile/Profile.vue")
        },
        {
            path: "/tools",
            name: "tools",
            component: () => import("@/view/pages/common/tools/Sign_Tool.vue")
        },
        {
            path: "/builder",
            name: "builder",
            component: () => import("@/view/pages/Builder.vue")
        },
        {
            path: "/oms",
            name: "oms_pages",
            component: () => import("@/view/pages/oms_pages/Vuetify.vue"),
            children: [{
                path: "/auth",
                name: "auth",
                component: () => import("@/view/pages/oms_pages/role/Auth.vue")
            },
            {
                path: "/role/list",
                name: "role-list",
                component: () => import("@/view/pages/oms_pages/role/List.vue")
            },
            {
                path: "/role/edit",
                name: "role-edit",
                component: () => import("@/view/pages/oms_pages/role/Edit.vue")
            },
            {
                path: "/user/list",
                name: "user-list",
                component: () => import("@/view/pages/oms_pages/user/List.vue")
            },
            {
                path: "/agent/list",
                name: "agent-list",
                component: () => import("@/view/pages/oms_pages/agent/List.vue")
            },
            {
                path: "/merchant/list",
                name: "merchant-list",
                component: () => import("@/view/pages/oms_pages/merchant/List.vue")
            },
            {
                path: "/merchant/edit",
                name: "merchant-edit",
                component: () => import("@/view/pages/oms_pages/merchant/Edit.vue")
            },
            {
                path: "/merchant/fund/account",
                name: "merchant-fund-account",
                component: () => import("@/view/pages/oms_pages/merchant/Fund_Account.vue")
            },
            {
                path: "/channel/list",
                name: "channel-list",
                component: () => import("@/view/pages/oms_pages/channel/Channel_List.vue")
            },
            {
                path: "/channel/interface/list",
                name: "channel-interface-list",
                component: () => import("@/view/pages/oms_pages/channel/Interface_List.vue")
            },
            {
                path: "/channel/interface/edit",
                name: "channel-interface-edit",
                component: () => import("@/view/pages/oms_pages/channel/Interface_Edit.vue")
            },
            {
                path: "/withdraw/list",
                name: "withdraw-list",
                component: () => import("@/view/pages/oms_pages/withdraw/List.vue")
            },
            {
                path: "/order/dial/test",
                name: "order_test",
                component: () => import("@/view/pages/oms_pages/order/TakeOrder.vue")
            },
            {
                path: "/order/list",
                name: "order_list",
                component: () => import("@/view/pages/oms_pages/order/Order_List.vue")
            },
            {
                path: "/order/notify/list",
                name: "notify_list",
                component: () => import("@/view/pages/oms_pages/order/Notify_List.vue")
            },
            {
                path: "/order/report",
                name: "order-report",
                component: () => import("@/view/pages/oms_pages/report/Order_Report.vue")
            },
            {
                path: "/fund/merchant/bills",
                name: "merchant_bills",
                component: () => import("@/view/pages/oms_pages/fund/Bills.vue")
            },

            {
                path: "/plugin/upload",
                name: "file-upload",
                component: () => import("@/view/pages/oms_pages/plugin/FileUpload.vue")
            },
            {
                path: "/setting/edit",
                name: "setting-edit",
                component: () => import("@/view/pages/oms_pages/setting/Edit.vue")
            },
            {
                path: "/log/list",
                name: "setting-edit",
                component: () => import("@/view/pages/oms_pages/log/List.vue")
            }
            ]
        },
        {
            path: "/merch",
            redirect: "/dashboard",
            name: "merch_pages",
            component: () => import("@/view/pages/oms_pages/Vuetify.vue"),
            children: [{
                path: "fund/account",
                name: "merchant-fund-account",
                component: () => import("@/view/pages/oms_pages/merchant/Fund_Account.vue")
            },
            {
                path: "fund/withdraw",
                name: "fund-withdraw",
                component: () => import("@/view/pages/merch_pages/fund/Withdraw_Manage.vue")
            },
            {
                path: "withdraw/record",
                name: "fund-withdraw-record",
                component: () => import("@/view/pages/merch_pages/fund/Withdraw_List.vue")
            },
            {
                path: "fund/bills",
                name: "fund-bills",
                component: () => import("@/view/pages/merch_pages/fund/Bills.vue")
            },

            {
                path: "order/list",
                name: "order_list",
                component: () => import("@/view/pages/merch_pages/order/Orders.vue")
            },

            {
                path: "order/report",
                name: "order-report",
                component: () => import("@/view/pages/merch_pages/order/Report.vue")
            },

            {
                path: "logs/list",
                name: "setting-edit",
                component: () => import("@/view/pages/merch_pages/logs/Login_Record.vue")
            },

            {
                path: "doc",
                name: "doc",
                component: () => import("@/view/pages/home_pages/Document.vue")
            }
            ]
        },

        ]
    },
    {
        path: "/home/",
        name: "home",
        redirect: "/home/doc",
        component: () => import("@/view/layout/HomeLayout"),
        children: [ 
        {
            path: "doc",
            name: "doc",
            component: () => import("@/view/pages/home_pages/Document.vue")
        }
        ]
    },

    {
        path: "/",
        component: () => import("@/view/pages/common/auth/login_pages/Login-1"),
        children: [{
            name: "login",
            path: "/login",
            component: () => import("@/view/pages/common/auth/login_pages/Login-1.vue")
        },
        {
            name: "register",
            path: "/register",
            component: () => import("@/view/pages/common/auth/login_pages/Login-1.vue")
        }
        ]
    },
    {
        path: "*",
        redirect: "/404"
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "/404",
        component: () => import("@/view/pages/common/error/Error-1.vue")
    },
    ]
});